import axios from "axios";
import { store } from './auth_redux/store'; // Import your Redux store
import { emitCustomEvent } from "react-custom-events";
import { logUserIn, logUserOut } from "./auth_redux/authReducer";
import { useLocalStorage } from "./useLocalStorage";

export const API_URL = "https://prsa-dashboard.2mkouas.com/api/";
// export const username = "prsa-test";
// export const password = "PRSA2024";

const handleRedirect = () => {
  window.location.href = '/login';
};

function getAxiosConfig() {
  // eslint-disable-next-line prefer-const
  let instanceConfig = {
    baseURL: API_URL,
    timeout: 5 * 60000,
    headers: {
      "Content-Type": "multipart/form-data"
    },
  };

  const axiosInstance = axios.create(instanceConfig);

  let isRefreshing = false;
  let failedQueue = [];

  // Helper function to process queued requests
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  axiosInstance.interceptors.request.use((config) => {
    const credentials = store.getState().auth.userCredentials;
    if (credentials) {
      config.headers.Authorization = `Bearer ${credentials.access}`;
    }

    const trackDownloadProgress = (event) => {
      const [base, pk, suffix] = config.url.split("/");
      const key = `${base}-${pk}-downloaded`;
      if (event.progress) {
        emitCustomEvent(key, Math.round(event.progress * 100));
      }
    };

    // Axios interceptor to monitor download progress
    config.onDownloadProgress = trackDownloadProgress;
    return config;
  });


  // Add a response interceptor to handle invalid token and refresh token logic
  axiosInstance.interceptors.response.use(
    (response) => {
      // If the response is successful, return the response
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const credentials = store.getState().auth.userCredentials;

      // Check if the error is related to an "invalid_token" response'
      if (error.response.data.code === "token_not_valid" && !originalRequest._retry) {
        // Mark the request as retried to avoid infinite loop
        originalRequest._retry = true;

        // Handle refreshing token
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            // Make a request to refresh the token
            const refreshResponse = await axiosInstance.post('/auth/refresh/', {
              refresh: credentials.refresh,
            });
            console.log("Apres refresh: ", refreshResponse);

            // Update the credentials in the Redux store and localStorage
            const newAccessToken = refreshResponse.data.access;
            const newCredentials = {
              ...credentials,
              access: newAccessToken,
            };

            store.dispatch(logUserIn(newCredentials));
            localStorage.setItem('prsa-usercredentials', JSON.stringify(newCredentials));
            //console.log("Apres refresh: ", refreshResponse);

            // Process the queue with the new access token
            processQueue(null, newAccessToken);
            isRefreshing = false;

            // Set the Authorization header for the retried request
            setTimeout(()=>{
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            },3000);
            

            return axiosInstance(originalRequest);
          } catch (refreshError) {
            processQueue(refreshError, null);
            isRefreshing = false;
            // Dispatch a logout action or handle the error
            store.dispatch(logUserOut());
            localStorage.removeItem("prsa-usercredentials");
            handleRedirect();
            return Promise.reject(refreshError);
          }
        } else {
          // If token is already being refreshed, queue the request
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              // Retry the original request with the new token
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
      }

      // If the error is not an invalid token error, reject it
      return Promise.reject(error);
    }
  )

  return axiosInstance;
}

export default getAxiosConfig;


