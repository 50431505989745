import axios from "../services_hooks/axios";

export const getFromApiFunction = async (
  endPoint: string,
  params: URLSearchParams = new URLSearchParams(),
  headers: any = {},
) => axios().get(endPoint, { params, ...headers });


export const createDataFunction = async (endPoint: string, payload: any) =>
  axios().post(endPoint, payload);

export const updateDataFunction = async (endPoint: string, payload: any) =>
  axios().patch(endPoint, payload);

export const deleteDataFunction = async (endPoint: string, payload: any) =>
  axios().delete(endPoint, payload);
