import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Flex, Form, InputNumber, Modal, Select, Space, Spin, Upload, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Farmer } from '../../models/FarmerModel';
import { Intrant } from '../../models/InputModel';
import { useNavigate } from 'react-router-dom';
import { DistributionRequest } from '../../models/DistributionModel';


interface AddDistPropsInterface {
  centers: any[];
  farmers: any[];
  farmer: any;
  rest: any;
}

const AddDist = (addDistPropsInterface: AddDistPropsInterface) => {
  const { farmers, centers, farmer, rest } = addDistPropsInterface;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCam, setIsModalOpenCam] = useState(false);
  const [form] = Form.useForm();
  const formDataDistri = new FormData();
  const dataDistri = new FormData();
  const [photoBenef, setPhotoBenef] = useState<string | null>(null);
  const [photoRecep, setPhotoRecep] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [currentCam, setCurrentCam] = useState<"benef" | "recep">("benef");
  const [isLoading, setIsLoading] = useState(false);
  const [intrantOptions, setIntrantOptions] = useState<any>([]);
  const [farmersOptions, setFarmersOptions] = useState<any>([]);
  const [intrants, setIntrants] = useState<Intrant[]>([]);
  const location = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [intrantValid, setIntrantValid] = useState(true);
  const [intrantMsg, setIntrantMsg] = useState("");
  const [lastChangeDistri, setLastChangeDistri] = useState<any>([]);

  useEffect(() => {
    // Fonction pour mettre à jour la largeur
    const handleResize = () => setWidth(window.innerWidth);

    // Ajouter l'écouteur d'événement pour détecter les changements de taille
    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    Intrant.fetchData().then((response: any) => {
      setIntrants(Intrant.fromJsonList(response.data.results));
    });
  }, []);



  useEffect(() => {

    if (farmers) {
      let allFarmers: any[] = [];
      farmers.map((element: any) => allFarmers.push({ value: element.id, label: element.name }))
      setFarmersOptions(allFarmers);
    }

    if (intrants) {
      let allIntrants: any[] = [];
      intrants.forEach(element => {
        allIntrants.push({ value: element.id, label: element.name });
      });
      setIntrantOptions([...allIntrants]);
    }
  }, [centers, farmers, intrants]);

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const benef = () => {
    setCurrentCam("benef");
    startCamera();
  }

  const recep = () => {
    setCurrentCam("recep");
    startCamera();
  }

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const showModal = () => {
    setIsModalOpen(true);
  }

  const handleOk = () => {
    //console.log(form.getFieldsValue().distributions);
    //setIsLoading(true);
    onFinish(form.getFieldsValue());

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Démarrer la caméra
  const startCamera = () => {
    setIsModalOpenCam(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        message.error("Erreur lors de l'accès à la caméra.");
      });
  };

  // Capturer la photo
  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        context.drawImage(videoRef.current, 0, 0, 300, 200);
        const imageData = canvasRef.current.toDataURL("image/png");
        currentCam === "benef" ?
          setPhotoBenef(imageData) :
          setPhotoRecep(imageData);
        stopCamera();
        setIsModalOpenCam(false);
        message.success("Photo capturée avec succès !");
      }
    }
  };

  // Arrêter la caméra
  const stopCamera = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
  };

  const checkIntrantValid = (values: any) => {
    let rem = "";
    farmer.remaining_distributions.map((e: any) => {
      if (e.input_id === values.intrant) {
        rem = e.quantity
        return rem;
      }
      return null;
    })
  }

  function base64ToBlob(base64: string) {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }




  // Soumettre le formulaire
  const onFinish = (values: any) => {
    /*const invalidFields: { field: string; message: string; }[] = [];
    let isValid = true;

    // Vérification des distributions
    values.distributions.forEach((distri: any, index: any) => {
      let rem = "";

      // Vérifiez chaque intrant et sa quantité
      if (distri.farm_input && distri.quantity) {
        const matchingEntry = farmer.remaining_distributions.find(
          (e: any) => e.input_id === distri.farm_input
        );

        if (matchingEntry) {
          rem = matchingEntry.quantity;
          if (rem < distri.quantity) {
            isValid = false;
            invalidFields.push({
              field: `distributions[${index}].quantity`,
              message: `Quantité maximale : ${rem}`,
            });
          }
        } else {
          isValid = false;
          invalidFields.push({
            field: `distributions[${index}].farm_input`,
            message: "Intrant non autorisé pour ce fermier",
          });
        }
      } else {
        isValid = false;
        invalidFields.push({
          field: `distributions[${index}].farm_input`,
          message: "Intrant et quantité requis",
        });
      }
    });
    

    if (isValid) {*/

      console.log(form.validateFields())
/*
      formDataDistri.append("distributions", JSON.stringify({ "create": values.distributions }));
      formDataDistri.append("farmer", farmer.id);
      formDataDistri.append("recipient_id_image", new File([base64ToBlob(photoBenef ? photoBenef : "")], "photoBenef_image.png"));
      formDataDistri.append("recipient_image", new File([base64ToBlob(photoRecep ? photoRecep : "")], "photoRecep_image.png"));

      DistributionRequest.setSession(formDataDistri).then((response: any) => {
        setIsLoading(false);
        setIsModalOpen(false);
        const id = response.data['id'];
        dataDistri.append('distributions', JSON.stringify({ "create": values.distributions }));

        DistributionRequest.updateSession(id, dataDistri).then((response: any) => {
          setIsLoading(false);
          //setIsModalOpen(true);
          message.success('Submit success!');
          form.resetFields();
        }).catch((error: any) => {
          setIsLoading(false);
          setIsModalOpen(false);
          message.error("erreur d'enregistrement!");
        });
      }).catch((error: any) => {
        setIsLoading(false);
        setIsModalOpen(false);
        message.error("erreur d'enregistrement!");
      });*/
    /*
    } else {
      // Affichez les erreurs
      console.log(values);
      console.log(form);
      console.log(form.getFieldValue('farm_input'));

      invalidFields.forEach(({ field, message }) => {
        console.log("field: ", field, ' - msg: ', message);
        form.setFields([
          {
            name: "distributions",
            errors: [message],
          },
        ]);
      });
    }*/


    /*if (values.intrant && values.quantity) {
      const formData = {
        ...values,
        photoBenef,
        photoRecep,
      };
      const formData2 = {
        "disctributions": [values.distributions],
        "farme": farmer.id,
        "recipient_id_image": photoBenef,
        "recipient_image": photoBenef
      }
      let rem = "";
      farmer.remaining_distributions.map((e: any) => {
        if (e.input_id === values.intrant) {
          rem = e.quantity
          return rem;
        }
        return null;
      })

      if (rem != "") {
        if (rem >= values.quantity) {
          Farmer.setDistribution(farmer.id, formData2).then((response: any) => {
            setIsLoading(false);
            setIsModalOpen(false);
            message.success('Submit success!');
            form.resetFields();
          }).catch((error: any) => {
            setIsLoading(false);
            setIsModalOpen(false);
            message.error("erreur d'enregistrement!");
          });
        } else {
          setIsLoading(false);
          message.error(`La quantité pouvant etre attribuer ne peux pas dépasser ${rem}`);
        }
      } else {
        setIsLoading(false);
        message.error(`Mon ${farmer.lastname} n'est pas habilité a recevoir ce type d'intrant`);
      }
    } else {
      setIsLoading(false);
      message.error(`Assurez d'avoir bien remplir les champs`);

    }*/

  };

  const handleCancel2 = () => {
    console.log("hello");
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.distributions) {
      const indexChanged = changedValues.distributions.findIndex(
        (item: any) => item !== undefined
      );

      if (indexChanged !== -1) {
        setLastChangeDistri(allValues.distributions[indexChanged]);
        console.log("Ligne modifiée:", {
          index: indexChanged,
          data: allValues.distributions[indexChanged],
        });
      }
    }






    /*
    const length = allValues.distributions.length
    let rem = "";
    if (allValues.distributions.length > 0) {
      const lastDistri = allValues.distributions[length - 1]
      console.log('Valeurs du intrant:', lastDistri);
      if (lastDistri != undefined) {
        if ((lastDistri.farm_input && lastDistri.quantity)) {
          console.log(farmer);
          farmer.remaining_distributions.map((e: any) => {
            if (e.input_id === lastDistri.farm_input) {
              rem = e.quantity
              return rem;
            }
            return null;
          })

          if (rem != "") {
            if (rem >= lastDistri.quantity) {
              setIntrantValid(true);
            } else {
              setIntrantValid(false);
              setIntrantMsg(`La quantité pouvant etre attribuer ne peux pas dépasser ${rem}`);
            }
          } else {
            setIntrantValid(false);
            setIntrantMsg(`${farmer.name} ${farmer.firstname} n'est pas habilité a recevoir ce type d'intrant`);
          }

        } else {
          setIntrantMsg("Toute les champs du dernier intrant ne sont pas remplir");
          setIntrantValid(false);
        }

      } else {
        setIntrantMsg("Toute les champs du dernier intrant ne sont pas remplir");
        setIntrantValid(false);

      }
    }*/
  };

  const checking_farm_input = (value: any) => {
    // Vérifiez chaque intrant et sa quantité
    if (value) {
      const matchingEntry = farmer.remaining_distributions.find(
        (e: any) => e.input_id === value
      );

      if (matchingEntry) {
        return "";
      } else {
        return "Intrant non autorisé pour ce fermier"
      }
    } else {
      return "Intrant requis"
    }

  }

  const checking_quantity = (value: any) => {
    // Vérifiez chaque intrant et sa quantité
    if (value)
      if (lastChangeDistri != undefined || lastChangeDistri.farm_input) {
        const matchingEntry = farmer.remaining_distributions.find(
          (e: any) => e.input_id === lastChangeDistri.farm_input
        );
        console.log(matchingEntry);
        if (matchingEntry != undefined)
          if (matchingEntry.quantity >= value) {
            return "";
          } else {
            return `La quantité pouvant etre attribuer ne peux pas dépasser ${matchingEntry.quantity}`
          }
        else
          return "Choix un intrant"
      } else {
        return "Choix un intrant"
      }
    else
      return "Quantité requis"

  }


  return (
    <>
      <Space size={'large'} className='mt-3'>

        <Button key={"add"} type='primary' onClick={showModal}
          className='bg-primary hover:bg-secondary text-white mb-3'>
          Ajouter
        </Button>

        <Button key={"fin"} type='primary' onClick={rest}
          className='bg-primary hover:bg-secondary text-white mb-3' >
          Terminer
        </Button>
      </Space>

      <Modal
        key={"addDist"}
        title="Ajouter une distribution"
        open={isModalOpen}
        onClose={handleCancel2}
        closeIcon={false}
        footer={
          [
            <Button key={"close"} onClick={handleCancel}>
              Cancel
            </Button>,

            <Button key={"submi"} type="primary" htmlType="submit" onClick={handleOk} disabled={isLoading}
              className='bg-primary hover:bg-secondary text-white'
            >
              {isLoading ? <Spin /> : 'Submit'}
            </Button>
          ]
        }
      >
        <Form
          key={"forme1"}
          form={form}
          layout='vertical'
          labelCol={{ span: 30 }}
          wrapperCol={{ span: 30 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={handleValuesChange}
        >

          <Form.List name="distributions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <div key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }}>
                      
                      <Flex justify='space-between' align='center' wrap="wrap" style={{  width: '100%' }}>
                        <Form.Item
                          {...restField}
                          key={"farm_input"}
                          label="Intrant"
                          name={[name, 'farm_input']}
                          required
                          style={(width > 500)
                            ? { width: '48%' }
                            : { width: '95%', margin: '3px auto' }}
                          rules={[
                            {
                              validator: async (_, value) => {
                                const rest = checking_farm_input(value);
                                console.log(rest);
                                if (!rest)
                                  return Promise.resolve();
                                else
                                  return Promise.reject(new Error(rest));

                              },
                            },
                          ]}
                        >
                          <Select
                            placeholder="Sélectionnez un intrant"
                            options={intrantOptions}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          key={"quantity"}
                          label="Quantité"
                          name={[name, 'quantity']}
                          //rules={[{ required: true, message: 'Please input!' }]}
                          style={(width > 500)
                            ? { width: '48%' }
                            : { width: '95%', margin: '3px auto' }}
                          rules={[
                            {
                              validator: async (_, value) => {
                                const rest = checking_quantity(value);
                                console.log(rest);
                                if (!rest)
                                  return Promise.resolve();
                                else
                                  return Promise.reject(new Error(rest));

                              },
                            },
                          ]}
                        >
                          <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>

                      </Flex>
                      <MinusCircleOutlined onClick={() => remove(name)}  className='ml-1'/>
                    </div>
                    <hr className='mb-3'/>
                  </>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => {
                    add();
                    // if (intrantValid)
                    //   add()
                    // else {
                    //   message.error(intrantMsg);
                    // }
                  }} block icon={<PlusOutlined />}>
                    Ajouter un intrant
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Flex justify='space-between' align='center' wrap="wrap">
            <Form.Item
              key={"pb"}
              label="Photo d'identité du bénéficiaire"
              name="recipient_id_image"
              valuePropName="recipient_id_image"
              required
              style={(width > 500)
                ? {}
                : { width: '100%', margin: '3px auto' }}
            >
              {!photoBenef && (
                <Button icon={<UploadOutlined />} onClick={benef} >
                  Capturer une photo
                </Button>
              )}

              {photoBenef && (
                <div>
                  <img
                    src={photoBenef}
                    alt="Photo Capturée"
                    style={{ marginTop: "10px", width: "200px", height: "100px" }}
                  />
                  <Button
                    type="dashed"
                    onClick={() => setPhotoBenef(null)}
                    style={{ marginTop: "10px" }}
                  >
                    Reprendre la photo
                  </Button>
                </div>
              )}
            </Form.Item>
            <Form.Item
              key={"pr"}
              label="Photo de réception"
              name="recipient_image"
              valuePropName="recipient_image"
              required
              style={(width > 500)
                ? { width: '50%' }
                : { width: '100%', margin: '3px auto' }}
            >
              {!photoRecep && (
                <Button icon={<UploadOutlined />} onClick={recep}>
                  Capturer une photo
                </Button>
              )}
              {photoRecep && (
                <div>
                  <img
                    src={photoRecep}
                    alt="Photo Capturée"
                    style={{ marginTop: "10px", width: "200px", height: "100px" }}
                  />
                  <Button
                    type="dashed"
                    onClick={() => setPhotoRecep(null)}
                    style={{ marginTop: "10px" }}
                  >
                    Reprendre la photo
                  </Button>
                </div>
              )}
            </Form.Item>
          </Flex>
        </Form>

        {/* Modal pour capturer la photo */}
        <Modal
          key={"photo"}
          title={currentCam === 'benef' ?
            "Photo du bénéficiaire" :
            "Photo de réception"}
          open={isModalOpenCam}
          onCancel={() => {
            stopCamera();
            setIsModalOpenCam(false);
          }}
          footer={[
            <Button key="cancel" onClick={() => setIsModalOpenCam(false)}>
              Annuler
            </Button>,
            <Button className='bg-primary' key="capture" type="primary" onClick={capturePhoto}>
              Capturer la photo
            </Button>,
          ]}
        >
          <video ref={videoRef} width="100%" height="auto" />
          <canvas
            ref={canvasRef}
            style={{ display: "none" }}
            width="300"
            height="200"
          />
        </Modal>
      </Modal>
    </>
  );
};

export default AddDist;