import React, { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { MAP_VIEWS } from './core/constants';
import country from "./core/departement.json";
import data, { dataPoint } from "./core/data";
import L from 'leaflet';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  FeatureGroup

} from "react-leaflet";
import { PopupComponent } from './MapMaker';



delete (L.Icon.Default.prototype)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});



const MapBase = () => {

  const [isVisible, setIsVisible] = useState(false);
  const position = [48.8566, 2.3522];
  const geojsonData = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [[[2.3522, 48.8566], [2.4000, 48.8566], [2.4000, 48.8000], [2.3522, 48.8000], [2.3522, 48.8566]]],
    },
  };
  const [viewIndex, setCurrentViewIndex] = useState(0);
  const bounds = L.geoJSON(country).getBounds();
  const [view, setView] = useState(MAP_VIEWS[viewIndex]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[viewIndex].id);

  const handleOpenPopup = () => {
    setIsVisible(true);
  };

  const handleClosePopup = () => {
    setIsVisible(false);
  };
  return (
    <>
      <div className="h-screen w-screen relative" style={{ zIndex: 1 }}>
        <div
          className="grow h-max w-full "
          style={{
            display: "grid",
          }}
        >
          <MapContainer bounds={bounds} zoom={12} touchZoom={false} style={{ height: '100vh', width: '100%' }}>
          <FeatureGroup>
                {country.features.map((feature, index) => {
                  let dataJson = feature;
                  
                    return (
                      <FeatureGroup key={`${dataJson.properties.name}-${index}`}>
                        {
                          <GeoJSON
                            data={dataJson}
                            style={{ color: ["yellow", "green"][viewIndex] }}
                          />
                        }
                        <Popup>
                          <span>
                          {dataJson.properties.name }
                            </span>
                            <span>
                          {dataJson.properties.Commune }
                            </span>
                            </Popup>
                        
                      </FeatureGroup>
                    );
                  
                })}
              </FeatureGroup>
              {
                dataPoint.map((marker, index) => {
                  return (
                    <Marker
                      position={[marker.latitude, marker.longitude]}
                      key={index}
                    >
                      <PopupComponent marker={marker} />
                     
                      
                    </Marker>
                  );
                })
              }
            <TileLayer
              key={tileKey} {...view.tileLayerInfos}
              />

          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default MapBase;