// serviceWorkerRegistration.js

// Définissez une URL relative vers le fichier du service worker dans le dossier 'public'
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] est l'adresse locale IPv6.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 sont considérées comme adresses IPv4 localhost.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );
  
  export function register() {
    //process.env.NODE_ENV === 'production' && 
    if ('serviceWorker' in navigator) {
      // Le chemin d'accès au fichier service-worker.js
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
      if (isLocalhost) {
        // Cette section est pour localhost. Elle vérifie si un service worker existe et le charge.
        checkValidServiceWorker(swUrl);
      } else {
        // Enregistre le service worker pour une PWA en production
        registerValidSW(swUrl);
      }
    }
  }
  
  function registerValidSW(swUrl) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // Un nouveau contenu est disponible ; demande à l'utilisateur de recharger la page.
                console.log('New content is available; please refresh.');
              } else {
                // Contenu précache est maintenant disponible.
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl) {
    // Vérifie si le service worker peut être trouvé.
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then((response) => {
        // Assurez-vous que le service worker existe.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // Pas de service worker. Rechargez la page pour retirer le service worker ancien.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Enregistre le service worker
          registerValidSW(swUrl);
        }
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  }
  
  // Fonction pour désenregistrer un service worker
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error(error.message);
        });
  }
  }