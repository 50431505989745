import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';

import { Button, message } from 'antd';

const ScanQRCode = (parameters: any) => {
  const {uuidSetter} = parameters; 
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [showScanner, setShowScanner] = useState(false);
  const [shouldScan, setShouldScan] = useState(false);



  // Fonction appelée après la détection du QR code
  const handleScan = (data: any) => {
    if (data) {
      setScanResult(data[0].rawValue); // Stocker le code QR scanné
      uuidSetter(data[0].rawValue);
      setShowScanner(false); // Fermer le scanner
    }
  };

  // Fonction appelée en cas d'erreur de scan
  const handleError = (err: any) => {
    console.error(err);
    //message.error('Erreur lors du scan du QR code');
  };

  // Fonction pour envoyer la requête avec le code scanné
 
  return (
    <div>
      {/* Bouton pour démarrer le scanner */}
      <Button 
        className='bg-primary'
       type="primary" onClick={() => showScanner ? setShowScanner(false) : setShowScanner(true)}>
        {showScanner ? 'Fermer le Scanner' : " Scanner un QR Code"}
      </Button>

      {/* Affichage du scanner si le bouton est cliqué */}
      {showScanner && (
         <Scanner onScan={handleScan} constraints={{
          facingMode: "user",
          width: 1280,
          height: 1280,
          frameRate: 30
         }}/>
      )}

      {/* Afficher le code scanné */}
      {scanResult && (
        <div>
          <p>QR Code scanné : {scanResult}</p>
        </div>
      )}
    </div>
  );
};

export default ScanQRCode;
