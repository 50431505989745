import BaseModel from "./BaseModel";

export class Intrant extends BaseModel {
  unit: number;
  static url: string = "inputs";

  constructor(id: number, name: string, unit: number) {
    super(id, name);
    this.unit = unit;
  }

  static fromJson(json: any): Intrant {
    return new this(json.id, json.name, json.unit);
  }
}
