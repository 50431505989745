import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Distribution from './pages/distribution';
import Login from './pages/Login';
import StoreProvider from './services_hooks/auth_redux/AuthStoreProvider';
import Dashboard from './pages/Dashboard';
import { useEffect, useState } from 'react';
import { useAppSelector } from './services_hooks/auth_redux/auth_hooks';
import PopUp from './components/PopUp';


function App() {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowPopUp(false); // Masquer le pop-up lorsque l'utilisateur est en ligne
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowPopUp(true); // Afficher le pop-up lorsque l'utilisateur est hors ligne
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Vérifier l'état de la connexion au chargement de l'application
    if (!isOnline) {
      setShowPopUp(true);
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isOnline]);

  const closePopUp = () => {
    //setShowPopUp(false);
  };


  useEffect(() => {
    console.log("Current state: ", isAuthenticated);
  }, [isAuthenticated]);
  return (
    <>
      {showPopUp && (
        <PopUp
          message="Veuillez vous connecter à Internet pour continuer."
          onClose={closePopUp}
        />
      )}
      {/* <Distribution /> */}
    </>
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/">
    //       <Dashboard />
    //     </Route>
    //     <Route path="/login">
    //       <Login />
    //     </Route>
    //   </Routes>
    // </BrowserRouter>
  );
}

export default App;
