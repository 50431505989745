import axios from "./axios";
// import axios from "axios";

export default class AuthServices {

    static async login(formData: any) {
        let data = formData;
        return await axios().post("/auth/", data)
    }

    static async getCurrentUser() {
        return await axios().get("users/me");
    }

    static async logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
    }

}