
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authReducer'

export const store = configureStore({
    reducer: {
        auth: authReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store

// 

// import { createStore, combineReducers } from "redux";
// import { authReducer } from "./authReducer";

// const rootReducer = combineReducers({
//     auth: authReducer,
// });

// const store = createStore(rootReducer);

// export default store;
