// PopUp.js
import React from 'react';
import { Modal } from 'antd';
interface PopUpInterface {
  message: any,
  onClose: any,
}

const PopUp = ( propsPopUp: PopUpInterface) => {
  const {message, onClose} = propsPopUp;
    return (
        <Modal
            title="Notification"
            visible={true}
            onCancel={onClose}
            closeIcon={false}
            footer={[
            //     <button key="close" onClick={onClose}>
            //         Fermer
            //     </button>
             ]}
        >
            <p>{message}</p>
        </Modal>
    );
};

export default PopUp;
