import React, { useEffect, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Grid, Input, Space, Typography } from 'antd';
import AuthServices from '../services_hooks/AuthServices';
import { useAppSelector } from '../services_hooks/auth_redux/auth_hooks';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from '../services_hooks/useLocalStorage';
import Credentials from '../models/CredentialsModel';
import { plainToClass } from 'class-transformer';
import { logUserIn } from '../services_hooks/auth_redux/authReducer';
import { useNavigate } from 'react-router-dom';

const { useBreakpoint } = Grid;

const { Text } = Typography;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [currentError, setCurrentError] = useState("");

  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
  const { setItem } = useLocalStorage();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const screens = useBreakpoint();


  useEffect(()=>{
    setTimeout(()=>setCurrentError(""), 15000)
    ;
  });

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        navigator('/');
      }, 3000);
    }
  },
    [isAuthenticated, navigator])

  const onFinish = (values: any) => {
    setLoading(true);
    setCurrentError("");
    AuthServices.login(values).then((response: any) => {
      setCurrentError("");

      let userCredentials = { ...response.data };
      setItem('prsa-usercredentials', JSON.stringify(userCredentials));
      //let usercredentials: Credentials = plainToClass(Credentials, userCredentials) as Credentials;

      const usercredentialsData = plainToClass(Credentials, userCredentials) as Credentials;
      const usercredentials = {
        refresh: usercredentialsData.refresh,
        access: usercredentialsData.access,
        // ajoutez d'autres propriétés si nécessaire
      };

      dispatch(logUserIn(usercredentials));
      setTimeout(() => {
        setLoading(false);
        navigator('/');
      }, 3000);
    }).catch((error: any) => {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.detail) {
        setCurrentError("Votre identifiant ou mot de passe est incorrect");
      } else {
        setCurrentError("Une erreur s'est produite, veuillez vérifiez vos identifiants et réessayer.");
      }
    });
  };

  return (
    <div className='prsa-login h-screen m-auto flex justify-around bg-gray-200'>
      {
        screens.sm &&
        <div className='m-auto'>
          <img src="./login_p.png" alt="" />
        </div>
      }
      <div className={`bg-white rounded-xl h-5/6 ${screens.sm ? "w-2/6 mr-20" : "w-full m-8"}  my-auto content-center p-8`}>
        <h2 className='text-center text-xl font-bold text-primary'>Bienvenue</h2>

        <Form
          name="login"
          initialValues={{ remember: true }}
          style={{ maxWidth: 360, display: 'grid' }}
          onFinish={onFinish}
          className='m-auto text-center mt-4 leading-4'
          
        >
          <Text type='danger' className='text-left' >
            <span>{currentError}</span>
          </Text>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Merci de saisir votre identifiant!' }]} className='text-left mb-3 mt-2' >
            <Input prefix={<UserOutlined />} placeholder="Identifiant" className='hover:border-primary' />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Merci de saisir votre mot de passe!' },]}
            className='text-left mt-3'
          >
            <Input.Password prefix={<LockOutlined />} type="password" placeholder="Mot de passe" className='hover:border-primary' />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" disabled={loading} loading={loading} iconPosition={loading ? 'end' : 'start'} className='bg-primary rounded-full hover:bg-primary'>
              Connexion
            </Button>

          </Form.Item>
        </Form>
      </div>


    </div>

  );
};

export default Login;