/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});


export function PopupComponent(props) {
  const { marker } = props;
  const map = useMap();
  return (
    <Popup>
      <div
        style={{
          display: "grid",
          gap: "3px",
        }}
      >
        <button
          onClick={() => map.flyTo([marker.latitude, marker.longitude], 18)}
          className="mb-4 border-2 border-black rounded-md p-2 bg-gray-200 hover:bg-gray-300 hover:border-gray-400"
        >
          Zoomer
        </button>
        <hr className="border border-purple-200" />

        <h3 className="text-lg font-bold">{marker.name}</h3>
        <h4 className="text-lg font-bold">Quantité Distribuée</h4>
       
        <div className="w-full">
          <div className="w-full max-w-md rounded-2xl">
            {marker &&
              marker.products.length > 0 ? (
                marker.products.map((product, index) => (
                  <div key={index} className="product-item flex justify-between mb-2">
                    <span className="font-medium">{product.name}</span>
                    <span className="text-gray-600">{product.quantity}</span>
                  </div>
                ))
              ) : (
                <p>No products available</p>
              )}
          </div>
        </div>
        <hr className="border border-purple-200" />
        
      </div>
    </Popup>
  );
}

