import { getFromApiFunction } from '../services_hooks/apiFunction';


export default class BaseModel extends Object {
  id: number | string;
  name: string;
  pk?: number;
  static url = "";

  constructor(id: number | string, name: string, pk?: number) {
    super();
    this.id = id;
    this.name = name;
    this.pk = pk;
  }

  /**
   * Default value for select input
   * @returns An instance of this class
   */
  static default(): any {
    return { value: 0, label: "Tout" };
  }

  static fromJson(json: any): BaseModel {
    return new this(json.id, json.name, json.pk);
  }

  static fromJsonList(array: Object[]): any[] {
    return array.map((value) => this.fromJson(value));
  }

  static toJson(value: BaseModel): any {
    return {
      id: value.id,
      name: value.name,
    };
  }

  static toJsonList(values: BaseModel[]): any {
    return values.map((value) => this.toJson(value));
  }

  static toSelectOption(values: BaseModel[]): any {
    return values.map((value) => {
      return {
        value: value.pk,
        label: value.name,
      };
    });
  }

  static fetchData(params: URLSearchParams = new URLSearchParams()): any {
    return getFromApiFunction(this.url, params);
  }
}
