import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  SettingOutlined,
  SearchOutlined,
  UserOutlined,
  DownSquareOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu, Input, Dropdown, Grid } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import { menuElementList } from '../../core/constants';
import { User } from '../../models/UserModel';
import AuthServices from '../../services_hooks/AuthServices';

type MenuItem = Required<MenuProps>['items'][number];

type ItemProps = {
  name: string;
  alert?: string;
  link?: string;
};

const Item: React.FC<ItemProps> = ({ name, alert, link }) => {
  return (
    <div className='flex justify-between content-end text-primary'>
      {link ?
        <Link to={link}>{name}</Link> : <span>{name}</span>
      }
      {alert && (
        <div className='items-end h-max w-10'>
          <span className='border px-2 py-1 rounded-md items-end'>{alert}</span>
        </div>
      )}
    </div>
  );
};

const items: MenuItem[] = [
  { key: '8', icon: <PieChartOutlined />, label: <Item name='Déconnexion' link='/deconnect' /> },
];
const { useBreakpoint } = Grid;

interface propsSidebar {
  handleOutlet: (val: boolean) => void;
}

const Sidebar = ({ handleOutlet }: propsSidebar) => {
  const [collapsed, setCollapsed] = useState(false);
  const [menu, setMenu] = useState(menuElementList);
  const [search, setSearch] = useState(menu);
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const screens = useBreakpoint();
  const [width, setWidth] = useState(window.innerWidth);
  const [widthApp, setWidthApp] = useState("256px");

  useEffect(() => {
    // Fonction pour mettre à jour la largeur
    const handleResize = () => setWidth(window.innerWidth);

    // Ajouter l'écouteur d'événement pour détecter les changements de taille
    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log("Width: ", width);
  }, [width]);


  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    if (!inputValue) {
      setSearch(menu);
    } else {
      const filteredMenu = menu
        .filter((item) => item.name.toLowerCase().includes(inputValue));
      setSearch(filteredMenu);
    }
  };

  useEffect(() => {
    AuthServices.getCurrentUser().then((result: any) => {
      const tmp = result.data;
      console.log(tmp);
      const usertmp = new User(
        tmp.pk,
        tmp.username,
        tmp?.email,
        tmp?.lastname,
        tmp?.firstname,
      );
      setUserInfo(usertmp);
    });
  }, []);

  useEffect(() => {
    console.log('etat Screen SM : ', screens.sm);
    (!screens.sm)
      ? setCollapsed(true)
      : setCollapsed(false);

  }, [screens.sm]);

  useEffect(() => {
    (screens.xs)
      ? setWidthApp(`${width}px`)
      : setWidthApp(`256px`);


  }, [screens.xs])

  useEffect(() => {
    console.log("collapsed: ", collapsed);
    screens.xs 
      ? handleOutlet((screens.xs != undefined && screens.xs && collapsed) 
        ? false
        : true
        )
      : handleOutlet(false)
  }, [collapsed, width]);

  return (
    <div style={{ width: `${!collapsed ? widthApp : '100px'}`, }}>
      <aside className='h-screen top-0 bg-gray-50 scroll-smooth scrollbar-thin scrollbar-custom fixed'>
        <nav id='sid' className={`p-3 h-full flex flex-col shadow-sm transition-all`} style={{ width: `${!collapsed ? widthApp : '100px'}`, }}>
          <div className=' m-3 p4 pb-2 flex justify-between items-center'>
            <img
              src='/logo.png'
              className={`overflow-hidden transition-all ${!collapsed ? 'w-14  ' : 'w-0'} `}
              alt="frsp-logo"
            />
            <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }} className='bg-primary hover:bg-secondary text-white'>
              {collapsed ?
                <MenuUnfoldOutlined className='sideBar-menu' /> : <MenuFoldOutlined className='sideBar-menu' />}
            </Button>
          </div>
          <ul className='flex-1'>
            <li>
              <Input
                className={` mx-auto mt-2 mb-2 bg-transparent placeholder-white-gray focus:bg-transparent text-gray-700 ${collapsed ? 'hidden' : ''}`}
                prefix={<SearchOutlined />}
                placeholder='Search'
                onChange={handleChange}
                style={{ width: '98%' }}
              />
            </li>
            <li>
              <SearchOutlined className={`${!collapsed ? 'hidden' : 'sidebar-search w-full'} text-gray-700`} />
            </li>
            <li>
              <Menu
                defaultSelectedKeys={['1']}
                mode="inline"
                theme='light'
                inlineCollapsed={collapsed}
                className='bg-gray-50 text-primary'
              >
                {
                  search.map((element: any) => {
                    return <Menu.Item key={element.id} icon={element.icon}>
                      <Item name={element.name} link={element.link} />
                    </Menu.Item>;
                  })
                }
              </Menu>
            </li>

          </ul>
          <div className='myfooter'>

            <Dropdown menu={{ items }} placement="topRight" className={`m-3 p-3`}>
              <div className={` rounded-xl flex leading-4  ${!collapsed ? 'border' : ''}`}>
                <div>

                </div>
                <img src="/unisexe.png" alt="" className={`w-10 h-10 rounded-full d  ${collapsed ? 'hidden' : ''} `} />
                <div className={`flex ml-3 text-primary justify-between w-52 items-start  ${collapsed ? 'hidden' : ''}`}>
                  <div className='text-left'>
                    <h4 className='font-semibold'>
                      {
                        (userInfo?.lastname && userInfo?.firstname)
                          ? userInfo?.lastname + " " + userInfo?.firstname
                          : userInfo?.name
                      }
                    </h4>
                    <span className='text-xs text-gray-800'>{userInfo?.email}</span>
                  </div>

                </div>
                <div className={`${!collapsed ? 'hidden' : ''}`}>
                  <img src="/unisexe.png" alt="" className='w-8 h-7 rounded-full d' />
                </div>
              </div>
            </Dropdown>
          </div>
        </nav>
      </aside >
    </div>
  );
};

export default Sidebar;
