export default class Credentials {
    refresh?: string;
    access?: string;

  constructor(
    refresh: string,
    access?: string,
  ) {
    this.access = access;
    this.refresh = refresh; 
  }
}
