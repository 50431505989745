import BaseModel from "./BaseModel";

export class User extends BaseModel {
  email?: string;
  lastname?: string;
  firstname?: string;
  static url: string = "users/me";

  constructor(id: number, name: string, email?: string, lastname?: string, firstname?: string) {
    super(id, name);
    this.email = email;
    this.lastname = lastname;
    this.firstname = firstname;
  }

  static fromJson(json: any): User {
    return new this(json.id, json.name, json.email, json.lastname, json.firstname);
  }
}
