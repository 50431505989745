import SateliteViewSvg from "../components/SateliteViewSvg";
import StreetViewSvg from "../components/StreetViewSvg";
import {
  ShareAltOutlined,
} from '@ant-design/icons';

// import { MapIcon } from "@heroicons/react/24/solid";

export const menuElementList: any[] = [
  { id: 1, name: "Distributions", link: "/distribution", icon: <ShareAltOutlined /> },
  { id: 2, name: "Map", link: "/mapping", icon: <ShareAltOutlined /> },
  // { id: 2, name: "Map", link: "/map", icon: <MapIcon /> },
];

export interface MapViewInterface {
  id: string;
  illustration: string;
  component?: any;
  viewName: string;
  tileLayerInfos: any;
}

const SATELITE_VIEW = {
  attribution:
    "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const DEFAULT_VIEW = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

export const MAP_VIEWS: MapViewInterface[] = [
  {
    id: "1",
    illustration: "/street.jpg",
    component: <StreetViewSvg />,
    viewName: "Rues",
    tileLayerInfos: DEFAULT_VIEW,
  },
  {
    id: "2",
    illustration: "/sat.jpg",
    component: <SateliteViewSvg />,
    viewName: "Satellite",
    tileLayerInfos: SATELITE_VIEW,
  },
];

