import { createDataFunction, getFromApiFunction, updateDataFunction } from "../services_hooks/apiFunction";
import BaseModel from "./BaseModel";

export class Farmer extends BaseModel {
  firstname: string;
  sex: string;
  remaining_distributions: string
  static url: string = "farmers";

  constructor(id: string, name: string, firstname: string, sex: string, remaining_distributions:string) {
    super(id, name);
    this.firstname = firstname;
    this.sex = sex;
    this.remaining_distributions = remaining_distributions;
  }

  static fromJson(json: any): Farmer {
    return new this(json.id, json.name, json.firstname, json.sex, json.remaining_distributions);
  }
  static fromJson2(json: any): Farmer {
    return new this(json.uuid, json.name, json.firstname, json.sex, json.remaining_distributions);
  }

  static getAFarmer(uuid: any): any {
    return getFromApiFunction(`${this.url}/${uuid}`);
  }

  static setDistribution(body: any): any {
    return createDataFunction(`sessions/`, body);
  }

}
