import React from 'react';
import MapBase from '../MapBase';

const Mapping = () => {
  return (
    <div className="h-full w-full">
      <MapBase />
    </div>
  );
};

export default Mapping;