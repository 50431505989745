export const dataPoint = [
    {
      "id": 1,
      "name": "Cotonou Centre",
      "longitude": 2.4361,
      "latitude": 6.3703,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 2,
      "name": "Porto-Novo Centre",
      "longitude": 2.6169,
      "latitude": 6.4969,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 3,
      "name": "Parakou Centre",
      "longitude": 2.6200,
      "latitude": 9.3372,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 4,
      "name": "Djougou Centre",
      "longitude": 1.6667,
      "latitude": 9.7000,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 5,
      "name": "Abomey Centre",
      "longitude": 1.9810,
      "latitude": 7.1794,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 6,
      "name": "Bohicon Centre",
      "longitude": 2.0717,
      "latitude": 7.1781,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 7,
      "name": "Natitingou Centre",
      "longitude": 1.3733,
      "latitude": 10.2994,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 8,
      "name": "Ouidah Centre",
      "longitude": 2.0845,
      "latitude": 6.3660,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 9,
      "name": "Kandi Centre",
      "longitude": 2.9386,
      "latitude": 11.1342,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    },
    {
      "id": 10,
      "name": "Lokossa Centre",
      "longitude": 1.7167,
      "latitude": 6.6388,
      "products" : [
        { name: 'Product A', quantity: 10 },
        { name: 'Product B', quantity: 20 },
        { name: 'Product C', quantity: 15 },
        { name: 'Product D', quantity: 8 },
        { name: 'Product E', quantity: 30 },
      ]
    }
  ];
  